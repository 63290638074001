.container {
	font-size: 12px;
}

.selectInput {
	width: 100%;
	border: 1px solid #aaa;
	border-radius: 8px;
	margin: 8px 0;
	outline: none;
	padding: 12px 38px;
	box-sizing: border-box;
	transition: 0.3s;
	background: none;
	/** for the dropdown indicator */
	appearance: none;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 1em;
	/* color: #6c757d; */
}

.selectInput:focus {
	border-color: #e2156a;
	box-shadow: 0 0 2px 0 #e2156a;
	caret-color: #e2156a;
	/* height: 3rem; */
}

.selectInput:focus + i {
	color: #e2156a;
	/* line-height: 2rem; */
	/* filter: invert(17%) sepia(56%) saturate(6502%) hue-rotate(326deg)
		brightness(92%) contrast(93%); */
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 4%;
	background-color: #fff;
	border-bottom: 1px solid #e5e7eb;
	line-height: 10px;
}

.logo {
	cursor: pointer;
}

.group {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: #374151;
}

.nav__links a,
.cta,
.overlay__content a {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 500;
	color: #edf0f1;
	text-decoration: none;
	/* padding: 2rem; */
}

.nav__links {
	list-style: none;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
	gap: 10px; /* Adjust this value to set the default space between menu items */
	text-wrap: nowrap;
}

.nav__links li {
	width: 150px;
	margin-left: 5px;
}

.group.active {
	color: #e2156a;
	border-bottom: 2px solid #e2156a;
	height: 3.9rem;
	filter: invert(17%) sepia(56%) saturate(6502%) hue-rotate(326deg)
		brightness(92%) contrast(93%);
	font-weight: 600;
	position: relative;
	z-index: -1;
}

.nav__links li a {
	color: #000;
	transition: color 0.3s ease 0s;
}

.nav__links li a:hover {
	color: #e2156a;
	filter: invert(17%) sepia(56%) saturate(6502%) hue-rotate(326deg)
		brightness(92%) contrast(93%);
}

.cta {
	padding: 9px 25px;
	background-color: rgba(0, 136, 169, 1);
	border: none;
	border-radius: 50px;
	cursor: pointer;
	transition: background-color 0.3s ease 0s;
}

.cta:hover {
	background-color: rgba(0, 136, 169, 0.8);
}

.menu {
	display: none;
}

.overlay__active {
	width: 100%;
}

.overlay__content {
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.overlay a {
	padding: 15px;
	font-size: 30px;
	display: block;
	transition: color 0.3s ease 0s;
}

.overlay a:hover,
.overlay a:focus {
	color: #e2156a;
}

.overlay .close {
	position: absolute;
	top: 20px;
	right: 45px;
	font-size: 60px;
	color: #edf0f1;
}

@media screen and (max-height: 450px) {
	.overlay a {
		font-size: 20px;
	}
	.overlay .close {
		font-size: 40px;
		top: 15px;
		right: 35px;
	}
}

@media only screen and (max-width: 1600px) {
	.nav__links {
		display: flex;
		flex-direction: row; /* Set flex-direction to row for horizontal scrolling */
		overflow-x: auto;
		overflow-y: hidden; /* Hide vertical overflow */
		-webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
		scroll-behavior: smooth; /* Enable smooth scrolling for modern browsers */
		-moz-scroll-behavior: smooth; /* Enable smooth scrolling for Mozilla Firefox */
		-ms-scroll-behavior: smooth; /* Enable smooth scrolling for Microsoft Edge */
	}

	.nav__links li {
		flex: 0 0 auto; /* Reset flex to allow items to shrink */
		margin-left: 0;
		margin-right: 0;
	}

	/* .nav__links, .cta {
        display: none;
    } Remove this line to keep navigation links and call-to-action button visible */
	.menu {
		display: initial;
		padding: 5px 5px 5px 5px;
		margin-left: 0;
		margin-right: 0;
	}
	.header {
		padding: 0px 8%;
	}
}

/* Media query for very small devices */
@media only screen and (max-width: 480px) {
	.nav__links {
		gap: 2px; /* Adjust this value to set the space between menu items for very small devices */
	}
}
@media only screen and (max-width: 768px) {
	.nav__links {
		gap: 2px; /* Adjust this value to set the space between menu items for small devices */
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 4%;
	background-color: #fff;
	border-bottom: 1px solid #e5e7eb;
	line-height: 10px;
}

.logo {
	cursor: pointer;
}

.group {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	gap: 4px;
	color: #374151;
}

.nav__links a,
.cta,
.overlay__content a {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 500;
	color: #edf0f1;
	text-decoration: none;
}

.search_form {
	min-width: 300px;
}

.nav__links {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin: 0;
	padding: 0;
}

.nav__links li {
	padding: 0px 10px;
	position: relative;
	float: left;
}

.group.active {
	/* Style for active link goes here */
	color: #e2156a; /* Change this to your desired color */
	border-bottom: 2px solid #e2156a;
	height: 3.9rem;
	filter: invert(17%) sepia(56%) saturate(6502%) hue-rotate(326deg)
		brightness(92%) contrast(93%);
	font-weight: 600;
}

.nav__links li a {
	color: #000;
	transition: color 0.3s ease 0s;
}

.nav__links li a:hover {
	color: #e2156a;
	filter: invert(17%) sepia(56%) saturate(6502%) hue-rotate(326deg)
		brightness(92%) contrast(93%);
}

.cta {
	padding: 9px 25px;
	background-color: rgba(0, 136, 169, 1);
	border: none;
	border-radius: 50px;
	cursor: pointer;
	transition: background-color 0.3s ease 0s;
}

.cta:hover {
	background-color: rgba(0, 136, 169, 0.8);
}

/* Mobile Nav */

.menu {
	display: none;
}

.hamburger {
	display: none;
	cursor: pointer;
}

/* Add the following lines to apply the animation to the bars */
.hamburger:hover .bar:nth-child(1) {
	animation: barAnimation 0.3s ease-in-out;
	animation-fill-mode: forwards;
}

.hamburger:hover .bar:nth-child(2) {
	animation: barAnimation 0.3s ease-in-out 0.1s;
	animation-fill-mode: forwards;
}

.hamburger:hover .bar:nth-child(3) {
	animation: barAnimation 0.3s ease-in-out 0.2s;
	animation-fill-mode: forwards;
}

.overlay {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	background-color: #24252a;
	overflow-x: hidden;
	transition: width 0.5s ease 0s;
}

.overlay__active {
	width: 100%;
}

.overlay__content {
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.overlay a {
	padding: 15px;
	font-size: 30px;
	display: block;
	transition: color 0.3s ease 0s;
	/* color: #000; */
}

.overlay a:hover,
.overlay a:focus {
	color: #e2156a;
}
.overlay .close {
	position: absolute;
	top: 20px;
	right: 45px;
	font-size: 60px;
	color: #edf0f1;
}

@media screen and (max-height: 450px) {
	.overlay a {
		font-size: 20px;
	}
	.overlay .close {
		font-size: 40px;
		top: 15px;
		right: 35px;
	}
}

@media only screen and (max-width: 1024px) {
	.nav__links,
	.cta {
		display: none;
	}
	.menu {
		display: initial;
		padding: 15px 25px 15px 25px;
	}
	.hamburger {
		display: block;
	}
	.hamburger:hover .bar:nth-child(2) {
		opacity: 0;
	}
	.hamburger:hover .bar:nth-child(1) {
		transform: translateY(8px) rotate(45deg);
	}
	.hamburger:hover .bar:nth-child(3) {
		transform: translateY(-8px) rotate(-45deg);
	}

	.header {
		padding: 0px 8%;
	}
}

/* ---------------------------------------------------------------------------------------- */

.searchInput {
	width: 100%;
	border: 1px solid #aaa;
	border-radius: 20px;
	margin: 8px 0;
	outline: none;
	padding: 12px;
	box-sizing: border-box;
	transition: 0.3s;
}

.mobSearchInput {
	width: 100%;
	border: 1px solid #aaa;
	border-radius: 20px;
	margin: 5px 0;
	outline: none;
	padding: 8px;
	box-sizing: border-box;
	transition: 0.3s;
}

.searchInput:focus {
	border-color: #e2156a;
	box-shadow: 0 0 2px 0 #e2156a;
	caret-color: #e2156a;
}

.mobSearchInput:focus {
	border-color: #e2156a;
	box-shadow: 0 0 2px 0 #e2156a;
	caret-color: #e2156a;
}

.searchInputWithIcon [type='search'] {
	padding-left: 2rem;
	background: #e5e7eb;
}

.searchInputWithIcon {
	position: relative;
}

.searchInputWithIcon i {
	position: absolute;
	left: 0;
	top: 8px;
	padding: 15px 10px;
	color: #aaa;
	transition: 0.3s;
}

.searchInputWithIcon .searchInput:focus + i {
	color: #e2156a;
}

.mobSearchInputWithIcon [type='search'] {
	padding-left: 2rem;
	background: #e5e7eb;
}

.mobSearchInputWithIcon {
	position: relative;
}

.mobSearchInputWithIcon i {
	position: absolute;
	left: 0;
	top: 8px;
	padding: 5px 10px;
	color: #aaa;
	transition: 0.3s;
}

.mobSearchInputWithIcon .searchInput:focus + i {
	color: #e2156a;
}

.cartBadge {
	position: absolute;
	top: -6px;
	right: -7px;
	background-color: #ef4444;
	color: #ffffff;
	font-weight: 500;
	border-radius: 50%;
	padding: 2px 5px; /* Adjust the padding as needed */
	font-size: 9px; /* Adjust the font size as needed */
}

.cartIcon {
	position: relative;
	display: inline-block;
}

.cartIcon:hover {
	transform: scale(1.1); /* Optional: Add a scale effect on hover */
}

.cartIcon:hover .cartBadge {
	background-color: #ef4444;
	color: #ffffff; /* Change the text color on hover */
	/* animation: bounce 0.5s ease normal; */
}
.searchContainer {
	width: 100%;
	text-align: center;
	display: none;
}

@media only screen and (max-width: 768px) {
	.searchContainer {
		display: block;
	}

	.mobSearchInput {
		width: 100%;
	}
}

.bar {
	display: block;
	width: 25px;
	height: 3px;
	margin: 5px auto;
	background-color: #e2156a;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

/* --------------------------------------------------------------------- */

.buttonLink {
	display: inline-block;
	padding: 1rem;
	background-color: #e2156a;
	color: #ffffff;
	text-decoration: none;
	border: none;
	border-radius: 8px;
	transition: background-color 0.3s ease;
	font-weight: 600;
	font-size: 12px;
	font-family: 'Nunito Sans', sans-serif;
	text-wrap: nowrap;
}

.form-control:focus {
	border: 0;
}

.buttonLink:hover {
	background-color: #d63384;
}

.buttonLink:focus,
.buttonLink:active {
	outline: none;
	background-color: #d63384;
}

/* -------------------------------------------------------------- */

.notificationPopupContainer {
	position: absolute;
	top: 6.5rem; /* Adjust top position as needed */
	/* right: 7rem; */
	width: 200px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.notificationPopupContent {
	padding: 10px;
}

.avatarPopupContainer {
	position: absolute;
	top: 6.5rem; /* Adjust top position as needed */
	right: 5rem;
	width: 200px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.avatarPopupContent {
	padding: 10px;
}

.avatarMobilePopupContainer {
	position: absolute;
	top: 6.5rem; /* Adjust top position as needed */
	right: 7rem;
	width: 200px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.avatarMobilePopupContent {
	padding: 10px;
}

/* Media query for responsiveness */

@media only screen and (max-width: 1024px) {
	.notificationPopupContainer {
		position: absolute;
		top: 6.5rem; /* Adjust top position as needed */
		/* right: 7rem; */
		width: 200px;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	}
}

@media only screen and (max-width: 768px) {
	.notificationPopupContainer {
		position: absolute;
		top: 6.5rem; /* Adjust top position as needed */
		/* right: 7rem; */
		width: 200px;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	}
}

@media only screen and (max-width: 450px) {
	.notificationPopupContainer {
		position: absolute;
		top: 6.5rem; /* Adjust top position as needed */
		/* right: 7rem; */
		width: 200px;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	}
	.notificationPopupContainer {
		top: 6.2rem;
		right: 7.5rem;
	}
	.avatarPopupContainer {
		top: 6.2rem;
		right: 7.5rem;
	}
	.avatarMobilePopupContainer {
		top: 6.2rem;
		right: 7.5rem;
	}
}

@media only screen and (max-width: 768px) {
	.notificationPopupContainer {
		top: 6rem;
		right: 6.5rem;
	}
	.avatarPopupContainer {
		top: 6rem;
		right: 6.5rem;
	}
	.avatarMobilePopupContainer {
		top: 6rem;
		right: 6.5rem;
	}
}

@media only screen and (max-width: 450px) {
	.notificationPopupContainer {
		top: 6rem;
		right: 0rem;
		width: 100%;
	}
	.avatarPopupContainer {
		top: 6rem;
		right: 0rem;
		width: 100%;
	}
	.avatarMobilePopupContainer {
		top: 6rem;
		right: 0rem;
		width: 100%;
	}
}

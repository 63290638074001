.form-control:focus {
    box-shadow: none;
}

.form-control {
    font-size: 12px;
    padding: 0
}

.inputSection:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25)
}
/* Common styles for all screen sizes */
.topbar {
	text-align: center;
	padding: 0.4rem;
	background-color: #f3f4f6;
	color: #1f2937;
	border-bottom: 1px solid #b1bac1;
	font-size: 12px;
	font-weight: 500;
}

.topbarLink {
	text-decoration: none;
	font-weight: 600;
	text-align: center;
	background-color: #f3f7f7;
	/* border-bottom: 1px solid #b1bac1; */
	font-size: 12px;
}

.topbar-link {
	text-decoration: none;
	font-weight: 600;
}

.quantityInput[type='number']::-webkit-inner-spin-button,
.quantityInput[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Hide spinners in Firefox */
.quantityInput[type='number'] {
	-moz-appearance: textfield;
}

.radioInput[type='radio']:after {
	width: 18px;
	height: 18px;
	border-radius: 15px;
	top: -1px;
	left: -1px;
	position: relative;
	background-color: #d1d3d1;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
	cursor: pointer;
}

.radioInput[type='radio']:checked:after {
	width: 18px;
	height: 18px;
	border-radius: 15px;
	top: -1px;
	left: -1px;
	position: relative;
	/* background-color: #ffa500; */
	background-color: #e2156a;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
	cursor: pointer;
}

.details {
	display: flex;
	gap: 10px;
	justify-content: space-around;
}

.productImage {
	position: relative;
	width: 405px;
	margin-bottom: 10px;
	/* z-index: -1; */
}

.productFavourite {
	top: 10px;
	position: absolute;
	right: 1rem;
	cursor: pointer;
}

.productHeader {
	font-size: 0.875rem;
	font-weight: 700;
	color: #1f2937;
}

.productCode {
	font-size: 0.815rem;
	font-weight: 500;
	color: #4b5563;
}

.productPrice {
	margin-top: 1.25rem;
	font-size: 0.75rem;
	font-weight: 600;
	color: #1f2937;
	line-height: 16px;
}

.productButtons button {
	border-radius: 8px;
	color: white;
	font-size: 0.75rem;
	margin-right: 0.7rem;
	border: none;
	padding: 0.5rem;
}

.otherBuyOptions {
	margin: 2.5rem 0;
	line-height: 16px;
}

.otherBuyOptions div {
	width: 70%;
	margin-bottom: 0.3rem;
}

.descriptionFont {
	width: 80%;
	font-size: 0.75rem;
	line-height: 0.8rem;
	color: #1f2937;
	font-weight: 500;
}

.productDetails {
	padding: 1rem;
	margin: 0 10rem;
}

.descriptionContainer {
	width: 50%;
}

.truncatedText {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	/* Show 3 lines by default */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	/* Add ellipsis if text overflows */
}

.showMore {
	display: block;
	/* Ensure correct formatting */
	text-align: right;
	/* Align button to the right */
	color: #1e499f;
	/* Blue color for button */
	border: none;
	/* Remove default border */
	background-color: transparent;
	/* Remove default background */
	cursor: pointer;
	/* Indicate clickable behavior */
}

.showMore:hover {
	text-decoration: underline;
	/* Underline on hover */
}

.expanded {
	-webkit-line-clamp: unset;
	/* Remove line clamping */
}

.fontInter {
	font-family: var(--font-inter);
}

.breadCrumbs {
	margin-left: 1.2rem;
	font-weight: 600;
}

.breadCrumbs div {
	text-decoration: none;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.breadCrumbs div:nth-child(2) {
	font-size: 12px;
	color: #1f2937;
	margin-right: 3px;
}

.breadCrumbs div {
	font-size: 12px;
	color: #e2156a;
	max-width: 15rem;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	display: inline-block;
}

.image-transition {
	transition: transform 0.5s ease-in-out;
}

@media screen and (max-width: 1000px) and (min-width: 900px) {
	.productDetails {
		margin: 0 10rem;
	}
}

@media screen and (max-width: 1100px) {
	.details {
		display: block;
	}

	.descriptionContainer {
		width: 100%;
	}

	.otherBuyOptions {
		width: 100%;
	}

	.otherBuyOptions div {
		width: 100%;
	}
}

@media screen and (max-width: 750px) {
	.productDetails {
		margin: 0 2rem;
	}
}

@media screen and (max-width: 500px) {
	.productImage {
		width: auto;
	}
}

.productShowMoreContent {
	padding: 0rem;
}

.productShowMoreContent p span {
	font-size: 12px;
	font-weight: normal;
	color: rgb(102, 102, 102);
	line-height: 0.8rem;
}

// @import url(https://fonts.googleapis.com/css?family=Ubuntu:400,500,700);
// @import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
$primary-color: #00c0cb;
$second-color: #e2156a;
$light-text: #abb0be;


.quantityInput[type='number']::-webkit-inner-spin-button,
.quantityInput[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Hide spinners in Firefox */
.quantityInput[type='number'] {
	-moz-appearance: textfield;
}

.lighterText {
	color: #abb0be;
}

.mainColorText {
	color: $primary-color;
}

.navbar {
	border-top: 1px solid #fff;
	background: url('https://www.qispackaging.com.au/App_Themes/QISPackaging/Images/bg-nav-rep-x.jpg')
		repeat-x;
	height: auto;
}
.container {
	margin: auto;
	width: 80%;
}

.badge {
	background-color: red;
	border-radius: 10px;
	color: white;
	display: inline-block;
	font-size: 12px;
	line-height: 1;
	padding: 3px 7px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
}

.shoppingCart {
	background: white;
	width: 22rem;
	position: absolute;
	top: 6.5rem;
	left: 70%;
	border-radius: 10px;
	padding: 20px;
	overflow: auto;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	opacity: 0;
	-webkit-transform-origin: left top 0;
	-webkit-transform: scale(0);
	transform-origin: left top 0;
	transform: scale(0);
	&.active {
		opacity: 1;
		-webkit-transform-origin: left top 0;
		-webkit-transform: scale(1);
		transform-origin: left top 0;
		transform: scale(1);
		z-index: 10;
	}
	.shoppingCartHeader {
		font-size: 12px;
		font-weight: 600;
		padding-bottom: 15px;
		display: flex;
		justify-content: space-between;

		.shoppingCartTotal {
			float: right;
		}
	}

	.shoppingCartItems {
		.itemName {
			display: block;
			font-size: 12px;
			font-weight: 500;
		}
		.itemDetail {
			display: block;
			font-size: 12px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.itemPrice {
			color: black;
			font-size: 12px;
			font-weight: 600;
			margin-right: 8px;
		}

		.itemQuantity {
			color: $light-text;
		}
	}
}

.shoppingCart:after {
	bottom: 100%;
	left: 89%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: white;
	border-width: 8px;
	margin-left: -8px;
}

.cartIcon {
	color: #515783;
	font-size: 24px;
	margin-right: 7px;
	float: left;
}

.button {
	background: $second-color;
	color: white;
	text-align: center;
	padding: 12px;
	text-decoration: none;
	display: block;
	border-radius: 10px;
	font-size: 12px;
	margin: 25px 0 15px 0;
	font-weight: 600;
	// height: 46px;
	&:hover {
		background: lighten($second-color, 3%);
	}
	i {
		padding-left: 5px;
	}
}

.clearfix {
	display: flex;
	gap: 10px;
}

.footer {
	display: flex;
	align-items: flex-start;
	gap: 10px;
}

.subTotal {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.subTotal div {
	font-size: 12px;
	font-weight: 600;
}

.wrapper {
  color: var(--gray-800, #1f2937);
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  margin-top: 2rem;
}

.notes {
  color: var(--gray-600, #4b5563);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
